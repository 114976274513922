@use 'src/app/variables' as *;

.radioGroupRoot {
  display: flex;
  align-items: flex-start;
}

.vertical {
  flex-direction: column;
  gap: var(--io-spacing-2);
}

.horizontal {
  flex-direction: row;
  gap: var(--io-spacing-2);
}
