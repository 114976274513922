@use 'src/app/variables' as *;

.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  background-color: var(--io-input-background);
  border: 1px solid;
  border-color: var(--io-input-border-color);
  border-radius: 4px;

  &.disabled {
    pointer-events: none;
    background-color: var(--io-bg-disabled);
    border-color: var(--io-border-disabled);
    color: var(--io-text-disabled);
  }

  &:hover:not(.hasError):not(:focus-within) {
    border-color: var(--io-input-border-color-hover);
  }

  &:focus-within {
    border-color: var(--io-input-border-color-focus);
    box-shadow: inset 0 0 0 1px var(--io-input-border-color-focus);
  }
}

.hasError {
  border-color: var(--io-danger);
}

.hidden {
  display: none;
}

.input,
.textarea {
  background-color: transparent;

  flex: 1;
  width: 100%;
  padding: toRem(8) toRem(12);

  border: 0;
  outline: none;

  /* We need to use at least 16px to avoid having iOS automatically zoom in our app when tapping on an input */
  font-size: var(--io-font-size-large);
  line-height: toRem(20);
  font-weight: 300;
  color: var(--io-input-text);
  cursor: text;

  &[disabled],
  &[disabled] {
    -webkit-text-fill-color: var(--io-text-disabled);

    &:placeholder {
      color: var(--io-input-placeholder-color);
    }
  }
}

.prefix {
  display: inline-block;
  margin-left: var(--io-spacing-3);
  color: var(--io-text-dimmed-700);
}

.iconBefore {
  margin-left: var(--io-spacing-3);
  display: inline-flex;
  align-items: center;
  color: var(--io-input-text);
}
.iconAfter {
  margin-right: var(--io-spacing-3);
  display: inline-flex;
  align-items: center;
  color: var(--io-input-text);
}

.suffix {
  display: inline-block;
  margin-right: var(--io-spacing-3);
  color: var(--io-text-dimmed-700);
}

/**
 * Sizing
 */

.medium {
  min-height: var(--io-input-height);
}
.iconBeforeMedium > svg,
.iconAfterMedium > svg {
  width: toRem(20);
  height: toRem(20);
}

.large {
  min-height: toRem(60);
  height: var(--io-input-large-height);
}
.iconBeforeLarge > svg,
.iconAfterLarge > svg {
  width: toRem(32);
  height: toRem(32);
}
