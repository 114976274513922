@use 'src/app/variables' as *;

.label {
  display: inline-block;
  color: var(--io-text-dimmed-700);
  font-size: var(--io-font-size-label);
  line-height: var(--io-line-height-body);
  margin-bottom: var(--io-spacing-1);
  overflow: hidden;
  text-overflow: ellipsis;
}

.helperText,
.errorText {
  display: inline-flex;
  align-items: flex-start;

  font-size: var(--io-font-size-small);
  line-height: var(--io-line-height-body);
  margin-top: var(--io-spacing-1);
}

.helperText {
  color: var(--io-text-dimmed-700);
}

.hasError,
.errorText {
  color: var(--io-danger);
}

.disabled {
  color: var(--io-text-disabled);
  pointer-events: none;
}

.complementaryTextLeadingIcon {
  flex-shrink: 0;
  display: inline-block;
  width: toRem(16);
  height: toRem(16);
  margin-right: var(--io-spacing-1);
  // Needed to make it visually fit with multiline
  margin-top: toRem(1);
}
