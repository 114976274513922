@use 'src/app/variables' as *;

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroIcon {
  width: toRem(52);
  height: toRem(52);
}

.heading {
  margin-bottom: var(--io-spacing-4);
}

.description {
  color: var(--io-text-dimmed-700);
  &:not(:last-child) {
    margin-bottom: var(--io-spacing-10);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: toRem(400);
  margin-bottom: var(--io-spacing-6);
}
